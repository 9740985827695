//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import  icon from '@assets/icon.png'

import  baohanicon from '@assets/index/baohan.png'
import  caigouicon from '@assets/index/caigou.png'
import  channelicon from '@assets/index/channel.png'
import  projecticon from '@assets/index/project.png'

import daiban from './components/daiban.vue'
import revenueEcharts from './components/revenueEcharts.vue'
import kaibaoProject from './components/kaibaoProject.vue'


export default {
    components:{daiban,revenueEcharts,kaibaoProject},

    data(){
        return{
            iconSrc: icon,
            baohaniconSrc:baohanicon,
            caigouiconSrc:caigouicon,
            channeliconSrc:channelicon,
            projexticonSrc:projecticon,

            amount:{
                settleData: {},
                guarData:{},
                hcgData:{},
                channelData:{},
                projectData:{},
            },
            lastLogin:{},
        }
    },
    computed: {
       
    },
    watch:{
        
    },
    methods: {
        //获取年月日
        getYearMonDayDate:function(){
            var myDate=new Date();
            var year = myDate.getFullYear();
            var month = myDate.getMonth()+1 < 10 ? "0" + (myDate.getMonth()+1) : myDate.getMonth()+1;
            var day = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate();
            return year + "." + month + "." + day
        },

        //获取星期
        getWeekDate:function() {
            var now = new Date();
            var day = now.getDay();
            var weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
            var week = weeks[day];
            return week;
        },
        toThousand:function(num){
            if(!num) return num;

            var str = num.toString();
            var strleft = str.split('.')[0];
            var strright = str.split('.')[1];

            if(!strleft) return num;

            var result = '';
            for (var i = strleft.length - 1, j = 1; i >= 0; i--, j++) {
                result = strleft.charAt(i) + result;
                if (j % 3 === 0 && i !== 0) {
                    result = ',' + result;
                }
            }

            if(!!strright){
                return result + '.' + strright ;
            }else{
                return result; 
            }
        },

        //获取数据概览
        getOverview:function(){
            this.$get(this.$store.getters.desktop + '/dataOverview',{},function(data){
                this.amount = data;
            })
        },
        //获取上次登录
        getLastLogin:function(){
            this.$get(this.$store.getters.desktop + '/lastLogin',{},function(data){
                this.lastLogin = data;
            })
        },
        goPath(val){
            this.goto({
                path:val
            })
        },
        copyHandler(item) {
            var {inviteUrl} = item;

            const textarea = document.createElement('textarea');
            textarea.value = inviteUrl;
            
            // 添加到文档中
            textarea.style.position = 'fixed';
            textarea.style.left = '0';
            textarea.style.top = '0';
            document.body.appendChild(textarea);
            
            // 选择文本
            textarea.select();
            
            try {
                const result = document.execCommand('copy');
                if (result) {
                    showMsg('复制成功，请前往粘贴分享!', 'success');
                } else {
                    showMsg('复制失败!');
                }
            } catch (error) {
                showMsg('复制错误:', error);
            }
            
            // 移除元素
            textarea.remove();
        },
    },
    mounted:function(){
        this.getOverview();
        this.getLastLogin();
    },
}
