//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import  icon from '@assets/icon.png'

export default {
    props: {

    },

    data () {
        return {
            iconSrc: icon,
            todoList: [],
            loading:false,
        }
    },
    computed: {

    },
    watch: {

    },
    methods: {
        //获取代办事项
        getTodoList:function(){
            this.loading = true;
            this.$get(this.$store.getters.desktop + '/todoList',{},function(data){
                this.todoList = data;
                this.loading = false;
            })
        },
    },
    mounted: function () {
        this.getTodoList();
    },

}
