//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {
    mixins: [],
    components: {
        
    },
    props: {

    },
    data() {
        return {
            tableData:[],
            pgData:{
                pageindex:1,
                sortname:'',
                sortorder:'desc',
                pagesize:10,
            },
        };
    },
    computed: {

    },
    watch: {},
    methods: {
        goPath(val){
            this.goto({
                path:val,
            })
        },
        getKaibiaoList:function(){
            this.$get(this.$store.getters.desktop + '/bidOpenTody',this.pgData,function(data){
                this.tableData = data.rows;
            })
        }
    },
    mounted() {
        this.getKaibiaoList();
    },
};
