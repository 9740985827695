//
//
//
//
//
//
//
//
//



export default {
    mixins: [],
    components: {
        
    },
    props: {
        options: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            xdata:[],
            ydata:[],
            loading:false
        };
    },
    computed: {
        optionData() {
            return {
                tooltip: {
                    trigger: "axis",
                    axisPointer: { 
                        type: 'line',
                        lineStyle:{
                            type:'solid',
                            width:2,
                        }
                    },
                    formatter: params => {
                        return params[0].name.slice(0,4) + "年" + params[0].name.slice(5,7) + "月" + "：" + params[0].value + " 元"
                    },
                },
                grid: {
                    top:"10%",
                    left: "8%",
                    right: "6%",
                    bottom: "9%",
                },
                xAxis: {
                    type: 'category',
                    data: this.xdata,
                    axisLine: {
                        show: false,
                    },
                    axisTick:{
                        show:false // 不显示坐标轴刻度线
                    },
                    axisLabel: {
                        interval:0,
                        textStyle: {
                            color: '#303133',//坐标值得具体的颜色
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: "{value} 元",
                        textStyle: {
                            color: '#303133',//坐标值得具体的颜色
                        }
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick:{
                        show:false // 不显示坐标轴刻度线
                    },
                },
                series: [
                    {
                        data: this.ydata,
                        type: 'line',
                        symbol:'circle',
                        symbolSize:[7,7],
                        color:'#3b68ff',
                    }
                ],

            };
        },
    },
    watch: {},
    methods: {
        getlineChart:function(){
            this.loading = true;
            this.$get(this.$store.getters.desktop + '/lineChart',{},function(data){
                data.lineChartData.forEach(item => {
                    this.xdata.push(item.value);
                    this.ydata.push(item.key);
                });
                this.loading = false;
            })
        }

    },
    mounted() {
        
    },
    created(){
        this.getlineChart();
    }
};
